import { call, put } from 'redux-saga/effects';

import StationClient from '../../api/StationClient';
import UserClient from '../../api/UserClient';
import { setStationInformationSuccessAction, setStationInformationFailureAction } from '../../redux/stations/actions';

export function* getStationInformationSaga({ payload }) {
    try {
        if (payload && payload.stationId) {
            const allStationInformation = yield call(StationClient.getStationInformation);
            const allStationInformationOpeningHours = yield call(StationClient.getStationOpeningHours);
            let stationName = '';
            let stationAddress = '';
            let stationDescription = [];
            let stationOpeningHours = {};
            let timeZone = '';
            let city = '';

            if (Array.isArray(allStationInformation)) {
                let stationInformation = null;

                allStationInformation.some(({ content, cityId }) => {
                    const stationInformationFound = content.features.find(
                        station => station.properties.stationId === payload.stationId
                    );

                    if (stationInformationFound) {
                        stationInformation = stationInformationFound;
                        city = cityId;

                        return true;
                    }

                    return false;
                });

                if (stationInformation) {
                    const allCities = yield call(UserClient.getCities);
                    const stationInformationPoiId = stationInformation.properties.poiId;

                    city = allCities && allCities.find(c => c.id === city);
                    timeZone = city && city.timeZone;
                    stationName = stationInformation.properties.name;
                    stationAddress = `${
                        stationInformation.properties.address ? `${stationInformation.properties.address} ` : ''
                    }${
                        stationInformation.properties.postal_code ? `${stationInformation.properties.postal_code} ` : ''
                    }${stationInformation.properties.city || ''}`;

                    if (stationInformationPoiId && Array.isArray(allStationInformationOpeningHours)) {
                        const stationOpeningHoursData = allStationInformationOpeningHours.find(
                            station => station.id === stationInformationPoiId
                        );

                        if (stationOpeningHoursData) {
                            if (Array.isArray(stationOpeningHoursData.descriptions)) {
                                stationDescription = [...stationOpeningHoursData.descriptions];
                            }

                            if (stationOpeningHoursData.timeDetails) {
                                stationOpeningHours = { ...stationOpeningHoursData.timeDetails };
                            }
                        }
                    }
                }
            }

            yield put(
                setStationInformationSuccessAction({
                    name: stationName,
                    address: stationAddress,
                    description: stationDescription,
                    openingHours: stationOpeningHours,
                    timeZone,
                })
            );
        }
    } catch (error) {
        yield put(setStationInformationFailureAction('Error'));

        console.error('[ERROR][SAGA] getStationInformationSaga', error);
    }
}
