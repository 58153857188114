import { createSelector } from 'reselect';
import moment from 'moment';

const stateSelector = state => state.billing;

export const fetchingSelector = createSelector(stateSelector, state => state.fetching);
export const listFilterSelector = createSelector(stateSelector, state => state.listFilter);
const listSelector = createSelector(stateSelector, state => state.list);
export const yearSelector = createSelector(stateSelector, state => (state.year == null ? '' : state.year));
export const monthSelector = createSelector(stateSelector, state => (state.month === null ? '' : state.month));

export const dateFilterSelector = createSelector([yearSelector, monthSelector], (year, monthNumber) => {
    if (year) {
        if (monthNumber) {
            const month = moment()
                .month(monthNumber)
                .format('MM');
            const startDate = `${year}-${month}-01`;
            const lastDayOfMonth = moment(startDate).daysInMonth();
            const endDate = `${year}-${month}-${lastDayOfMonth}`;
            return { startDate, endDate };
        }
        const startDate = `${year}-01-01`;
        const endDate = `${year}-12-31`;
        return { startDate, endDate };
    }
    return undefined;
});

export const profileSelector = createSelector(stateSelector, state => (state.profile == null ? '' : state.profile));
export const tripsSelector = createSelector(
    [listSelector, yearSelector, monthSelector, profileSelector],
    (list, year, month, profile) => {
        if (!list) return list;
        if (!year && !month && !profile) return list.trips;
        return list.trips.filter(
            item =>
                (!year || item.year === year) &&
                (typeof month === 'number' ? item.month === month : true) &&
                (!profile || item.profileId === profile)
        );
    }
);

export const uniqueTripsSelector = createSelector([tripsSelector], tripsList => {
    if (!tripsList) {
        return tripsList;
    }
    const tripsListUniqueMap = tripsList.reduce((map, cur) => {
        if (!map.has(cur.trip.tripId)) {
            map.set(cur.trip.tripId, cur);
        }
        return map;
    }, new Map());

    const tripsListUnique = [...tripsListUniqueMap.values()];
    return tripsListUnique;
});

export const othersSelector = createSelector([listSelector, yearSelector, monthSelector], (list, year, month) => {
    if (!list) return list;
    if (!year && !month) return list.others;
    return list.others.filter(item => item.year === year && (typeof month === 'number' ? item.month === month : true));
});
export const oldInvoicesSelector = createSelector(listSelector, state => (state || {}).old || []);
