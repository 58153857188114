import {
    ACTION_GET_STATION_INFORMATION,
    ACTION_SET_STATION_INFORMATION_SUCCESS,
    ACTION_SET_STATION_INFORMATION_FAILURE,
} from './types';

export const getStationInformationAction = payload => ({
    type: ACTION_GET_STATION_INFORMATION,
    payload,
});

export const setStationInformationSuccessAction = payload => ({
    type: ACTION_SET_STATION_INFORMATION_SUCCESS,
    payload,
});

export const setStationInformationFailureAction = payload => ({
    type: ACTION_SET_STATION_INFORMATION_FAILURE,
    payload,
});
