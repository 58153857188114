import OpenIDClient from './OpenIDClient';
import ApiClient from './ApiClient';

class BookingClient extends ApiClient {
    init = apiConf => {
        this.apiConf = apiConf;
    };

    getConf = () => this.apiConf;

    getModels = (cityId = null, serviceType = null, isUserAuthenticated = true) => {
        let queryParam = '';
        if (cityId && serviceType) queryParam = `?cityId=${cityId}&serviceType=${serviceType}`;
        else if (cityId) queryParam = `?cityId=${cityId}`;
        else if (serviceType) queryParam = `?serviceType=${serviceType}`;

        return this.get(
            !isUserAuthenticated && this.apiConf.scheduledBooking.anonymousBrowsing !== false
                ? OpenIDClient.anonymousOpenID
                : OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}models${queryParam}`
        );
    };

    getAvailableModelsByStation = (
        startDate = '',
        endDate = '',
        cityId,
        oneWayTrip = false,
        isUserAuthenticated = true
    ) => {
        let queryParam = `startDate=${startDate}&endDate=${endDate}&oneWayTrip=${oneWayTrip}`;
        if (cityId) queryParam = `startDate=${startDate}&endDate=${endDate}&cityId=${cityId}&oneWayTrip=${oneWayTrip}`;
        return this.get(
            !isUserAuthenticated && this.apiConf.scheduledBooking.anonymousBrowsing !== false
                ? OpenIDClient.anonymousOpenID
                : OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}scheduledBooking/bookingrequests/stations/models?${queryParam}`
        );
    };

    getAssetsOfModel = (modelId, isUserAuthenticated = true) =>
        this.get(
            !isUserAuthenticated && this.apiConf.scheduledBooking.anonymousBrowsing !== false
                ? OpenIDClient.anonymousOpenID
                : OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}models/${modelId}/assets`
        );

    getPOIs = (isUserAuthenticated = true) => {
        return this.get(
            !isUserAuthenticated && this.apiConf.scheduledBooking.anonymousBrowsing !== false
                ? OpenIDClient.anonymousOpenID
                : OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}mapping/layers`
        );
    };

    createScheduledBookingRequest = payload => {
        return this.post(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}scheduledBooking/bookingrequests`,
            payload
        );
    };

    getBookings = () => {
        return this.get(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}scheduledBooking/bookingrequests`
        );
    };

    getBookingById = bookingID => {
        return this.get(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}scheduledBooking/bookingrequests/${bookingID}`
        );
    };

    cancelScheduledBookingRequest = bookingId => {
        return this.delete(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}scheduledBooking/bookingrequests/${bookingId}`
        );
    };

    getPaidBookings = () => {
        return this.get(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}scheduledBooking/bookingrequests/status/active`
        );
    };

    getCities = (isUserAuthenticated = true) =>
        this.get(
            !isUserAuthenticated && this.apiConf.scheduledBooking.anonymousBrowsing !== false
                ? OpenIDClient.anonymousOpenID
                : OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}cities`
        );
}

export default new BookingClient();
