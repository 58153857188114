export const NEW_BOOKING_CLEAR = '@bookings/NEW_BOOKING_CLEAR';

export const NEW_BOOKING_SET_CURRENT_STEP = '@bookings/NEW_BOOKING_SET_CURRENT_STEP';

export const NEW_BOOKING_UPDATE_DATA = '@bookings/NEW_BOOKING_UPDATE_DATA';

export const NEW_BOOKING_FETCH_MODELS_BY_STATION = '@bookings/NEW_BOOKING_FETCH_MODELS_BY_STATION';
export const NEW_BOOKING_FETCH_MODELS_BY_STATION_SUCCESSFUL =
    '@bookings/NEW_BOOKING_FETCH_MODELS_BY_STATION_SUCCESSFUL';
export const NEW_BOOKING_FETCH_MODELS_BY_STATION_FAILED = '@bookings/NEW_BOOKING_FETCH_MODELS_BY_STATION_FAILED';

export const GET_MODEL_ASSETS = '@bookings/GET_MODEL_ASSETS';
export const FETCH_MODEL_ASSETS_FINISHED = '@bookings/FETCH_MODEL_ASSETS_FINISHED';

export const SET_IS_FETCHING_MODELS_BY_STATION_FINISHED = '@bookings/SET_IS_FETCHING_MODELS_BY_STATION_FINISHED';

export const NEW_BOOKING_CREATE_SB_REQUEST = '@bookings/NEW_BOOKING_CREATE_SB_REQUEST';
export const NEW_BOOKING_CREATE_SB_REQUEST_SUCCESSFUL = '@bookings/NEW_BOOKING_CREATE_SB_REQUEST_SUCCESSFUL';

export const NEW_BOOKING_CREATE_SB_REQUEST_FAILED = '@bookings/NEW_BOOKING_CREATE_SB_REQUEST_FAILED';

export const NEW_BOOKING_SET_CONFIRM_ADDING_DIALOG_OPENED = '@bookings/NEW_BOOKING_SET_CONFIRM_ADDING_DIALOG_OPENED';

export const BOOKINGS_FETCH_SB_REQUESTS = '@bookings/BOOKINGS_FETCH_SB_REQUESTS';
export const BOOKINGS_FETCH_SB_REQUESTS_SUCCESSFUL = '@bookings/BOOKINGS_FETCH_SB_REQUESTS_SUCCESSFUL';
export const BOOKINGS_FETCH_SB_REQUESTS_FAILED = '@bookings/BOOKINGS_FETCH_SB_REQUESTS_FAILED';

export const BOOKINGS_FETCH_SB_REQUEST_BY_ID = '@bookings/BOOKINGS_FETCH_SB_REQUEST_BY_ID';
export const BOOKINGS_FETCH_SB_REQUEST_BY_ID_SUCCESSFUL = '@bookings/BOOKINGS_FETCH_SB_REQUEST_BY_ID_SUCCESSFUL';
export const BOOKINGS_FETCH_SB_REQUEST_BY_ID_FAILED = '@bookings/BOOKINGS_FETCH_SB_REQUEST_BY_ID_FAILED';

export const BOOKINGS_FETCH_PAID_REQUESTS = '@bookings/BOOKINGS_FETCH_PAID_REQUESTS';
export const BOOKINGS_FETCH_PAID_REQUESTS_SUCCESSFUL = '@bookings/BOOKINGS_FETCH_PAID_REQUESTS_SUCCESSFUL';
export const BOOKINGS_FETCH_PAID_REQUESTS_FAILED = '@bookings/BOOKINGS_FETCH_PAID_REQUESTS_FAILED';

export const BOOKINGS_CANCEL_SB_REQUEST = '@bookings/BOOKINGS_CANCEL_SB_REQUEST';
export const BOOKINGS_CANCEL_SB_REQUEST_SUCCESSFUL = '@bookings/BOOKINGS_CANCEL_SB_REQUEST_SUCCESSFUL';
export const BOOKINGS_CANCEL_SB_REQUEST_FAILED = '@bookings/BOOKINGS_CANCEL_SB_REQUEST_FAILED';

export const BOOKINGS_SET_SB_REQUESTS_ORDER = '@bookings/BOOKINGS_SET_SB_REQUESTS_ORDER';
export const BOOKINGS_FETCH_CITIES_REQUEST = '@bookings/BOOKINGS_FETCH_CITIES_REQUEST';
export const BOOKINGS_FETCH_CITIES_REQUEST_SUCCESSFUL = '@bookings/BOOKINGS_FETCH_CITIES_REQUEST_SUCCESSFUL';
export const BOOKINGS_FETCH_CITIES_REQUEST_FAILED = '@bookings/BOOKINGS_FETCH_CITIES_REQUEST_FAILED';
