import {
    NEW_BOOKING_CLEAR,
    NEW_BOOKING_SET_CURRENT_STEP,
    NEW_BOOKING_UPDATE_DATA,
    NEW_BOOKING_FETCH_MODELS_BY_STATION,
    NEW_BOOKING_FETCH_MODELS_BY_STATION_SUCCESSFUL,
    NEW_BOOKING_FETCH_MODELS_BY_STATION_FAILED,
    NEW_BOOKING_CREATE_SB_REQUEST,
    NEW_BOOKING_CREATE_SB_REQUEST_SUCCESSFUL,
    NEW_BOOKING_CREATE_SB_REQUEST_FAILED,
    SET_IS_FETCHING_MODELS_BY_STATION_FINISHED,
    NEW_BOOKING_SET_CONFIRM_ADDING_DIALOG_OPENED,
    BOOKINGS_FETCH_SB_REQUESTS,
    BOOKINGS_FETCH_SB_REQUESTS_SUCCESSFUL,
    BOOKINGS_FETCH_SB_REQUESTS_FAILED,
    BOOKINGS_CANCEL_SB_REQUEST,
    BOOKINGS_CANCEL_SB_REQUEST_SUCCESSFUL,
    BOOKINGS_CANCEL_SB_REQUEST_FAILED,
    BOOKINGS_SET_SB_REQUESTS_ORDER,
    BOOKINGS_FETCH_PAID_REQUESTS,
    BOOKINGS_FETCH_PAID_REQUESTS_SUCCESSFUL,
    BOOKINGS_FETCH_PAID_REQUESTS_FAILED,
    BOOKINGS_FETCH_CITIES_REQUEST,
    BOOKINGS_FETCH_CITIES_REQUEST_SUCCESSFUL,
    BOOKINGS_FETCH_CITIES_REQUEST_FAILED,
    BOOKINGS_FETCH_SB_REQUEST_BY_ID,
    BOOKINGS_FETCH_SB_REQUEST_BY_ID_SUCCESSFUL,
    BOOKINGS_FETCH_SB_REQUEST_BY_ID_FAILED,
    GET_MODEL_ASSETS,
    FETCH_MODEL_ASSETS_FINISHED,
} from './types';

export const newBookingClear = () => ({ type: NEW_BOOKING_CLEAR });

export const setNewBookingCurrentStep = payload => ({
    type: NEW_BOOKING_SET_CURRENT_STEP,
    payload,
});

export const updateNewBookingData = payload => ({
    type: NEW_BOOKING_UPDATE_DATA,
    payload,
});

export const fetchNewBookingModelsByStation = () => ({
    type: NEW_BOOKING_FETCH_MODELS_BY_STATION,
});

export const fetchNewBookingModelsByStationSuccessful = payload => ({
    type: NEW_BOOKING_FETCH_MODELS_BY_STATION_SUCCESSFUL,
    payload,
});

export const fetchNewBookingModelsByStationFailed = () => ({
    type: NEW_BOOKING_FETCH_MODELS_BY_STATION_FAILED,
});

export const setIsFetchingModelsByStationFinished = value => ({
    type: SET_IS_FETCHING_MODELS_BY_STATION_FINISHED,
    value,
});

export const getModelAssets = value => ({
    type: GET_MODEL_ASSETS,
    value,
});

export const fetchModelAssetsFinished = payload => ({
    type: FETCH_MODEL_ASSETS_FINISHED,
    payload,
});

export const createNewBookingSBRequest = agreeToTerms => ({
    type: NEW_BOOKING_CREATE_SB_REQUEST,
    agreeToTerms,
});

export const createNewBookingSBRequestSuccessful = payload => ({
    type: NEW_BOOKING_CREATE_SB_REQUEST_SUCCESSFUL,
    payload,
});

export const createNewBookingSBRequestFailed = () => ({
    type: NEW_BOOKING_CREATE_SB_REQUEST_FAILED,
});

export const setNewBookingConfirmAddingDialogOpened = payload => ({
    type: NEW_BOOKING_SET_CONFIRM_ADDING_DIALOG_OPENED,
    payload,
});

export const fetchSBRequests = () => ({
    type: BOOKINGS_FETCH_SB_REQUESTS,
});

export const fetchSBRequestsSuccessful = payload => ({
    type: BOOKINGS_FETCH_SB_REQUESTS_SUCCESSFUL,
    payload,
});

export const fetchSBRequestsFailed = () => ({
    type: BOOKINGS_FETCH_SB_REQUESTS_FAILED,
});

export const fetchSBRequestByID = id => ({
    type: BOOKINGS_FETCH_SB_REQUEST_BY_ID,
    id,
});

export const fetchSBRequestByIDSuccessful = payload => ({
    type: BOOKINGS_FETCH_SB_REQUEST_BY_ID_SUCCESSFUL,
    payload,
});

export const fetchSBRequestByIDFailed = () => ({
    type: BOOKINGS_FETCH_SB_REQUEST_BY_ID_FAILED,
});

export const fetchPaidSBRequests = () => ({
    type: BOOKINGS_FETCH_PAID_REQUESTS,
});

export const fetchPaidSBRequestsSuccessful = payload => ({
    type: BOOKINGS_FETCH_PAID_REQUESTS_SUCCESSFUL,
    payload,
});

export const fetchPaidSBRequestsFailed = () => ({
    type: BOOKINGS_FETCH_PAID_REQUESTS_FAILED,
});

export const cancelSBRequest = payload => ({
    type: BOOKINGS_CANCEL_SB_REQUEST,
    payload,
});

export const cancelSBRequestSuccessful = () => ({
    type: BOOKINGS_CANCEL_SB_REQUEST_SUCCESSFUL,
});

export const cancelSBRequestFailed = () => ({
    type: BOOKINGS_CANCEL_SB_REQUEST_FAILED,
});

export const setSBRequestsOrder = payload => ({
    type: BOOKINGS_SET_SB_REQUESTS_ORDER,
    payload,
});

export const fetchCitiesRequest = () => ({
    type: BOOKINGS_FETCH_CITIES_REQUEST,
});

export const fetchCitiesRequestSuccessful = payload => ({
    type: BOOKINGS_FETCH_CITIES_REQUEST_SUCCESSFUL,
    payload,
});

export const fetchCitiesRequestFailed = () => ({
    type: BOOKINGS_FETCH_CITIES_REQUEST_FAILED,
});
