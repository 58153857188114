import {
    ACTION_GET_STATION_INFORMATION,
    ACTION_SET_STATION_INFORMATION_SUCCESS,
    ACTION_SET_STATION_INFORMATION_FAILURE,
} from './types';

const initState = {
    fetching: false,
    stationInformation: {
        name: '',
        address: '',
        description: [],
        openingHours: {},
    },
    stationInformationError: '',
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_GET_STATION_INFORMATION:
            return {
                ...initState,
                fetching: true,
            };

        case ACTION_SET_STATION_INFORMATION_SUCCESS:
            return {
                ...initState,
                stationInformation: action.payload,
                fetching: false,
            };

        case ACTION_SET_STATION_INFORMATION_FAILURE:
            return {
                ...initState,
                stationInformationError: action.payload,
                fetching: false,
            };

        default:
            return state;
    }
};

export default reducer;
