import axios from 'axios';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/LocalStorage';
import langLabel from '../constants/lang';

export const getValidLang = (lg, availableLanguages, defaultLang) =>
    lg && availableLanguages.findIndex(lang => lang === lg) > -1 ? lg : defaultLang;

export const getLocalLanguage = () => getLocalStorageItem('language');

export const getDefaultFleetLanguage = () => getLocalStorageItem('defaultFleetLanguage');

export const saveLocalLanguage = language => setLocalStorageItem('language', language);

export const saveDefaultFleetLanguage = defaultLang => setLocalStorageItem('defaultFleetLanguage', defaultLang);

export default async availableLanguages => {
    const allL10n = await axios
        .get(`/fe-api/anonymous/fo/localise?${process.env.REACT_APP_VERSION || process.env.REACT_APP_BUILD_ID}`)
        .then(({ data }) => data);

    return {
        availableLanguages: availableLanguages.map(key => ({ name: langLabel[key] || key, code: key })),
        locales: allL10n,
    };
};
