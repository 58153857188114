import { call, put, select } from 'redux-saga/effects';

import moment from 'moment-timezone';
import BookingClient from '../../api/BookingClient';
import StationClient from '../../api/StationClient';
import {
    fetchNewBookingModelsByStationFailed,
    fetchNewBookingModelsByStationSuccessful,
} from '../../redux/bookings/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';
import { allCitiesSelector, newBookingDataSelector, selectedCityIdSelector } from '../../selectors/bookings';
import { isAuthenticateSelector } from '../../selectors/login';
import { SERVICE_ROUND_TRIP_BOOKING } from '../../helpers/bookings';

export default function*() {
    try {
        const selectedServiceType = SERVICE_ROUND_TRIP_BOOKING;
        const isUserAuthenticated = yield select(isAuthenticateSelector);

        const allCities = yield select(allCitiesSelector);
        const selectedCityId = yield select(selectedCityIdSelector);

        const poisResults = yield call(BookingClient.getPOIs, isUserAuthenticated);
        const poisDetailsData = [];
        const poisId = [];

        poisResults
            .filter(p => p.cityId === selectedCityId)
            .forEach(poiResult => {
                if (poiResult.content && Array.isArray(poiResult.content.features)) {
                    poiResult.content.features.forEach(poiData => {
                        if (poiData && poiData.properties && poiData.properties.poiId) {
                            const poiCoordinates = (poiData.geometry && poiData.geometry.coordinates) || [];
                            poisDetailsData.push({ ...poiData.properties, coordinates: poiCoordinates });
                            poisId.push(poiData.properties.poiId);
                        }
                    });
                }
            });

        const modelsDetailsResults = yield call(
            BookingClient.getModels,
            selectedCityId,
            selectedServiceType,
            isUserAuthenticated
        );

        const modelsId = modelsDetailsResults.map(model => model.id);

        const bookingData = yield select(newBookingDataSelector);
        const { selectedStartDate, selectedEndDate } = bookingData;

        const selectedCity = allCities.find(c => c.id === selectedCityId);
        const roundTripTypeServiceIds = [];
        if (selectedCity && Array.isArray(selectedCity.services)) {
            selectedCity.services.forEach(service => {
                if (service.type === SERVICE_ROUND_TRIP_BOOKING) {
                    roundTripTypeServiceIds.push(service.id);
                }
            });
        }

        const formattedStartDate =
            (selectedStartDate &&
                moment
                    .tz(selectedStartDate, selectedCity && selectedCity.timeZone ? selectedCity.timeZone : undefined)
                    .utc()
                    .format()) ||
            null;

        const formattedEndDate =
            (selectedEndDate &&
                moment
                    .tz(selectedEndDate, selectedCity && selectedCity.timeZone ? selectedCity.timeZone : undefined)
                    .utc()
                    .format()) ||
            null;

        const allStationInformationOpeningHours = yield call(StationClient.getStationOpeningHours, isUserAuthenticated);

        const modelsByStationResults = yield call(
            BookingClient.getAvailableModelsByStation,
            formattedStartDate,
            formattedEndDate,
            selectedCityId,
            false,
            isUserAuthenticated
        );

        const filteredModelsByStationData =
            (Array.isArray(modelsByStationResults) &&
                modelsByStationResults.filter(
                    station =>
                        station.poiId &&
                        poisId.includes(station.poiId) &&
                        roundTripTypeServiceIds.includes(station.serviceId)
                )) ||
            [];

        filteredModelsByStationData.forEach(function stationCallBack(station, indexStation) {
            let stationOpeningHours = null;

            if (allStationInformationOpeningHours.length > 0) {
                const foundStation = allStationInformationOpeningHours.find(poi => poi.id === station.poiId);
                stationOpeningHours = (foundStation && foundStation.timeDetails) || null;
            }

            const stationData = poisDetailsData.find(poi => poi.poiId === station.poiId);

            if (stationData) {
                this[indexStation] = { ...stationData, ...station, stationOpeningHours };

                if (Array.isArray(station.models) && station.models.length > 0) {
                    station.models.forEach(function modelCallBack(model, indexModel) {
                        if (model.id && modelsId.includes(model.id)) {
                            const modelData = modelsDetailsResults.find(m => m.id === model.id);
                            this[indexModel] = { ...modelData, ...model };
                        }
                    }, station.models);
                }
            }
        }, filteredModelsByStationData);

        yield put(
            fetchNewBookingModelsByStationSuccessful({
                stations: filteredModelsByStationData,
            })
        );
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(fetchNewBookingModelsByStationFailed());
            yield put(messagesShow('internalServerError', 'error'));
            console.error('error in fetchModelsByStation', error);
        }
    }
}
